import { apiGet } from '@/app/Dataman';
import { INGREDIENTS_AND_NUTRIENTS } from './paths';

export default {
  getFoodByGTIN: (gtin) => {
    return apiGet(`${INGREDIENTS_AND_NUTRIENTS.foodByGTIN}/${gtin}`);
  },
  getDsldByUPC: (upc) => {
    return apiGet(`${INGREDIENTS_AND_NUTRIENTS.dsldFood}/${upc}`);
  },
  getDsldByBrandName: (brand) => {
    const params = {
      brand: brand,
      start: 0,
      itemsPerPage: 20,
    };

    return apiGet(`${INGREDIENTS_AND_NUTRIENTS.dsldFood}`, { params });
  },
};
