import Backend from 'App/Dataman-Backend';
import { upperFirst } from './utils/Helpers';
import modules from './utils/Modules';
import {
  CAT_BRANDS,
  CAT_COMPANIES,
  CAT_CONTACTS,
  CAT_COMPONENT_CATEGORIES,
  CAT_COMPONENT_PACKAGES,
  CAT_FEATURE_OPTIONS,
  CAT_FEATURES,
  CAT_FILES,
  CAT_NEWS,
  CAT_COMPANY_NEWS,
  CAT_PRODUCTS,
  STATS_CATEGORIES_MAP,
  CAT_NICE_CLASSES,
  CAT_TRADEMARKS,
  CAT_PATENT_CITATIONS,
  CAT_EVENTS,
  CAT_PATENT_RESEARCH,
  CAT_CONSUMABLE_VARIATIONS,
  CAT_PRICE_SOURCE_TYPES,
} from 'App/utils/Collections';
import { getReport } from './services/reports';
import { searchProducts } from './services/products/productSearch';
import { dealsAPI } from './api/deals';
import { assistantService } from '@/app/api/assistant';
import { userSessionsAPI } from 'App/api/userSessions';
import similarProducts from '@/app/api/find-similar';
import ingredientsAndNutrients from '@/app/api/ingredients-and-nutrients';

export class ApiError extends Error {
  constructor(message, response, ...rest) {
    super(message);
    this.response = response;
    this.extra = rest;
  }
}

const { apiHost, apiUrlLogin, apiUrlSuffix, apiUrlProofRequests } = Backend.config;
const { apiUrlTypes, apiUrlProductStatuses, apiUrlUser } = Backend.config;
const { apiUrlProductBrands, apiUrlCompanyCategories, apiUrlLeads, apiUrlResearchesCategories } = Backend.config;
const { apiUrlCountries, apiUrlCountryRegions, apiUrlEconomicRegions, apiUrlMediaResourceTypes } = Backend.config;
const { apiUrlProducts, apiUrlComponents, apiUrlCompanies, apiUrlContacts, apiUrlTrademarks } = Backend.config;
const {
  apiUrlProductsStats,
  apiUrlComponentsStats,
  apiUrlCompaniesStats,
  apiUrlContactsStats,
  apiUrlProductsTotal,
  apiUrlCompaniesTotal,
} = Backend.config;
const { apiAnalysisTypes, apiProductAnalysisOrders, apiUrlPatentDescriptions, apiUrlPatentClaims } = Backend.config;
const { apiUrlComments, apiUrlNews, apiUrlSearch, apiUrlProductOrders, apiUrlProductImages } = Backend.config;
const { apiUrlStandingReports, apiUrlUserBookmarks, apiUrlUserFilters, apiUrlResearchesDomains } = Backend.config;
const { apiFiles, apiUrlFilesGroups, apiUrlUserGroups, apiUrlPatentContents, apiUrlCategories } = Backend.config;
const { apiOrderStatuses, apiUrlPatents, apiUrlPatentsExplore, apiUrlResearchesAuthors } = Backend.config;
const { apiProductConsumableVariations, apiUrlModules, staffStats, staffStatsItem } = Backend.config;
const { apiSamples, apiSampleConditions, apiComponentPackages, apiComponentCategories } = Backend.config;
const { resetPasswordRequest, resetPasswordReset, apiUrlAdditionalFieldNames } = Backend.config;
const { apiUrlUsers, apiUrlUsersStaffStatistics, apiUrlCompanyImages, apiUrlNewsImages, apiUrlRoles } = Backend.config;
const { apiUrlBrandImages, apiUrlActivitySpheres, apiUrlProductFeatures, apiUrlProductFeatureOptions } = Backend.config;
const {
  apiNiceClasses,
  apiProductFiles,
  apiUrlPatentCpcClassification,
  apiUrlResearches,
  apiUrlChangePassword,
  apiUrlMarketCountriesFiles,
} = Backend.config;
const {
  apiUrlPatentIpcClassification,
  apiCpcClassifications,
  apiIpcClassifications,
  apiUrlCountriesProductMarkets,
  apiUrlProductVariations,
  apiUrlProductMarketCountries,
  apiUrlCurrencies,
  apiUrlCustomerAddresses,
} = Backend.config;
const {
  apiPatentCitations,
  apiPatents,
  apiPatentResearch,
  apiPatentResearchImages,
  apiPatentResearchDocument,
  apiPatentResearchDocumentComment,
  apiUrlTrademarkStatuses,
  apiUrlsTrademarkOffices,
  apiPatentInternalNotes,
  apiUrlRecommendedProducts,
  apiUrlTrademarkHolders,
  apiUrlTrackingInformationContents,
  apiUrlEvents,
  apiUrlEventTypes,
  apiUrlEventThemes,
  apiUrlEventAccesses,
  apiUrlEventStatus,
  apiUrlEventAtendingRequest,
  apiUrlEventImages,
  apiUrlAttendingRequests,
  apiUrlPriceSourceTypes,
} = Backend.config;

export async function apiGet(url, params = {}) {
  return Backend.get({ url, ...params });
}

export async function apiPost(url, params = {}) {
  return Backend.post({ url, ...params });
}

export async function apiPostJSON(url, data, params = {}) {
  return Backend.postJSON(data, { url, ...params });
}

export async function apiPut(url, params = {}) {
  return Backend.put({ url, ...params });
}

export async function apiDelete(url, params = {}) {
  return Backend.delete({ url, ...params });
}

export async function apiPatch(url, params = {}) {
  if (!params.headers) {
    params.headers = {};
  }
  if (!params.headers['content-type']) {
    params.headers['content-type'] = 'application/merge-patch+json';
  }
  return Backend.patch({ url, ...params });
}

const Filter = {
  getProductTypes: (params) => apiGet(apiUrlTypes, { params: { ...params } }),
  getCompanyTypes: (params) => apiGet(apiUrlTypes, { params: { ...params, modules: modules[CAT_COMPANIES] } }),
  getContactTypes: (params) => apiGet(apiUrlTypes, { params: { ...params, modules: modules[CAT_CONTACTS] } }),
  getFilesGroups: (params) => apiGet(apiUrlFilesGroups, { params }),
  getProductFeatures: (params) => apiGet(apiUrlProductFeatures, params),
  getProductFeature: (id) => apiGet(`${apiUrlProductFeatures}/${id}`),
  getProductStatuses: (params) => apiGet(apiUrlProductStatuses, params),
  getProductBrands: (params) => apiGet(apiUrlProductBrands, params),
  getCountries: (params) => apiGet(apiUrlCountries, params),
  getCountryRegions: (params) => apiGet(apiUrlCountryRegions, params),
  getEconomicRegions: (params) => apiGet(apiUrlEconomicRegions, params),
  getCompanyCategories: (params) => apiGet(apiUrlCompanyCategories, params),
  getMediaResourceTypes: (params) => apiGet(apiUrlMediaResourceTypes, params),
  getCpcClassification: (params) => apiGet(apiUrlPatentCpcClassification, params),
  getIpcClassification: (params) => apiGet(apiUrlPatentIpcClassification, params),
  getTrademarkStatuses: (params) => apiGet(apiUrlTrademarkStatuses, params),
  getEventTypes: (params) => apiGet(apiUrlEventTypes, params),
  getEventThemes: (params) => apiGet(apiUrlEventThemes, params),
  getEventAccesses: (params) => apiGet(apiUrlEventAccesses, params),
  getEventStatuses: (params) => apiGet(apiUrlEventStatus, params),
};

function SaveLead(data) {
  return new Promise((resolve, reject) => {
    Backend.post({ url: apiUrlLeads, data, auth: false, is_protected: true }).then(resolve).catch(reject);
  });
}

// --------------------------------- User
const User = {
  getToken: async (data) => {
    // eslint-disable-next-line no-unused-vars
    const { token, ...credentials } = data || {};
    const response = await Backend.post({
      url: apiUrlLogin,
      data: credentials,
      auth: false,
      is_protected: true,
    });
    Backend.setToken(response.data);
    return response;
  },
  getLocalToken: () => {
    const json = localStorage.getItem('wims_token') || '';
    let data;
    try {
      data = JSON.parse(json) || {};
    } catch (e) {
      data = {};
    }
    return data;
  },
  setLocalToken: (data) => localStorage.setItem('wims_token', JSON.stringify(data)),
  clearLocalToken: () => localStorage.removeItem('wims_token'),
  setToken: Backend.setToken,
  refreshToken: Backend.refreshToken,
  getUser: (data) => apiGet(apiUrlUser, { data }),
  updateFilter: (id, data) => apiPatch(`${apiUrlUserFilters}/${id}`, { data, is_protected: true }),
  subscribeUsersToFilter: (filterID, roleId) =>
    apiPost(`${apiUrlUserFilters}/${filterID}/batch_clone`, { data: { roleId } }),

  getFilters: (params, conf = {}) => apiGet(apiUrlUserFilters, { params, ...conf }),
  createFilters: (data) => apiPost(apiUrlUserFilters, { data, is_protected: true }),
  deleteFilter: (id) => apiDelete(`${apiUrlUserFilters}/${id}`, { is_protected: true }),
  subscribeToFilter: (id) => apiPost(`${apiUrlUserFilters}/${id}/clone`),

  getBookmarks: (data) => apiGet(apiUrlUserBookmarks, { data }),
  createBookmarks: (data) => apiPost(apiUrlUserBookmarks, { data, is_protected: true }),
  deleteBookmark: (id) => apiDelete(`${apiUrlUserBookmarks}/${id}`, { is_protected: true }),
  updProfile: async (data) => Backend.patch({ url: apiUrlUser, params: data }),
  changePassword: (data) => apiPost(apiUrlChangePassword, { data }),
};

const staffStatsEntries = Object.keys(STATS_CATEGORIES_MAP).map((cat) => [
  cat,
  {
    getGroupedList: (params) =>
      apiGet(`${staffStats[cat]}_group_by`, {
        params,
        headers: { Accept: 'application/ld+json' },
      }),
    getList: (params) =>
      apiGet(staffStats[cat], {
        params,
        headers: { Accept: 'application/ld+json' },
      }),
    getItem: (id, params) => apiGet(`${staffStats[cat]}/${id}`, { params }),
  },
]);

const staffStatsItemEntries = Object.keys(STATS_CATEGORIES_MAP).map((cat) => [
  cat,
  {
    getList: (params) =>
      apiGet(staffStatsItem[cat], {
        params,
        headers: { Accept: 'application/ld+json' },
      }),
    getItem: (id, params) => apiGet(`${staffStatsItem[cat]}/${id}`, { params }),
  },
]);

// noinspection JSValidateJSDoc
/**
 * @param {string} url
 * @returns {{
 *    getList: (function(*): Promise<AxiosResponse<any>>),
 *    getItem: (function(*): Promise<AxiosResponse<any>>),
 *    create: (function(*): Promise<AxiosResponse<any>>),
 *    update: (function(*): Promise<AxiosResponse<any>>),
 *    remove: (function(*): Promise<AxiosResponse<any>>),
 * }}
 */
function makeRequestsDict(url) {
  return {
    getList: (params, conf) => apiGet(url, { ...conf, params, ld: true, is_protected: true }),
    getItem: (id) => apiGet(`${url}/${id}`, { is_protected: true }),
    create: (data) => apiPost(url, { data, is_protected: true }),
    update: (id, data) => apiPut(`${url}/${id}`, { data, is_protected: true }),
    remove: (id) => apiDelete(`${url}/${id}`, { is_protected: true }),
  };
}

const DB = {
  testLogout: Backend.testFail,
  getProducts: searchProducts,
  createTrackingInformationContents: (data) => {
    const formData = new FormData();
    if (data.billing_informations) {
      formData.append('tracking_information_id', data.billing_informations.tracking_information_id);
      formData.append('file', data.billing_informations.file);
    }
    return Backend.post({
      url: apiUrlTrackingInformationContents,
      headers: {
        'Content-type': 'multipart/form-data',
      },
      data: formData,
    });
  },
  getTrackingInformationContents: (params) => apiGet(apiUrlTrackingInformationContents + apiUrlSuffix, { params }),
  deleteTrackingInformationContents: (id) => apiDelete(`${apiUrlTrackingInformationContents}/${id}`),

  getProductDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlProducts}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlProducts}/${id}`, { is_protected: true });
    }
  },
  getProductsStats: (params) => apiGet(apiUrlProductsStats, { params, is_protected: true }),
  postProductOrder: (id, data) => apiPost(`${apiUrlProducts}/${id}/order`, { data }),
  getProductAnalysisOrders: (params) => apiGet(apiProductAnalysisOrders + apiUrlSuffix, { params }),
  getProductHistory: (id) => apiGet(`${apiUrlProducts}/${id}/history`),
  getProductSnapshotById: (id, snapshot_id) => apiGet(`${apiUrlProducts}/${id}/history/snapshots/${snapshot_id}`),
  getProductCountByCategories: (params) => apiGet(apiUrlProductsTotal, { params }),
  getCompaniesCountByCategories: (params) => apiGet(apiUrlCompaniesTotal, { params }),
  getProductImages: (product_id, params = {}) =>
    apiGet(apiUrlProductImages, {
      params: {
        ...params,
        'product.id': product_id,
        itemsPerPage: 100,
      },
    }),
  getProductImage: (id) => apiGet(`${apiUrlProductImages}/${id}`),
  getProductFile: async (fileId) => {
    const res = await Backend.loadBlobData({
      url: `${apiProductFiles}/${fileId}`,
    });

    return res;
  },
  cloneProduct,
  cloneProducts,
  createProduct: (data) => {
    return apiPost(apiUrlProducts, { data, is_protected: true }).then(async (res) => {
      if (res.status === 201) {
        await uploadImages(CAT_PRODUCTS, data.images, {
          product_id: res.data.id,
        }).catch();
      } else {
        throw new ApiError('Can not save product', res);
      }
      return res;
    });
  },
  updateProduct: async (id, data) => {
    const { images, ...rest } = data || {};
    const res = await Backend.patch({
      data: rest,
      headers: { 'content-type': 'application/merge-patch+json' },
      url: `${apiUrlProducts}/${id}`,
      is_protected: true,
    });
    if (images !== void 0) {
      await uploadImages(CAT_PRODUCTS, images, { product_id: id }).catch();
    }
    return res;
  },

  customerAddresses: {
    getList: (params) => apiGet(apiUrlCustomerAddresses + apiUrlSuffix, { params }),
  },

  trademarkHolders: {
    getList: (params) => apiGet(apiUrlTrademarkHolders + apiUrlSuffix, { params }),
  },

  recommendedProducts: {
    getList: (params) => apiGet(apiUrlRecommendedProducts + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiUrlRecommendedProducts}/${id}`, { params }),
    update: (id, data) => {
      let preparedData = {};
      if (data.products && data.products.length) {
        preparedData.comment = data.products[0].comment;
      }
      if (data.read !== undefined) {
        preparedData.read = data.read;
      }
      return apiPatch(`${apiUrlRecommendedProducts}/${id}`, {
        data: preparedData,
        headers: { 'content-type': 'application/merge-patch+json' },
      });
    },
    create: (data) => apiPost(apiUrlRecommendedProducts, { data }),
  },

  products: {
    remove: (id) => apiDelete(`${apiUrlProducts}/${id}`),
    toggleRedesign: (id, isRedesigned) => {
      return apiPut(`${apiUrlProducts}/${id}/images/redesign`, {
        data: { isRedesigned },
      });
    },
  },
  categories: {
    getList: (params) => apiGet(apiUrlCategories, { params }),
    getReadable: (params) => apiGet(`${apiUrlCategories}/readable_by_me`, { params }),
    getWritable: (params) => apiGet(`${apiUrlCategories}/writable_by_me`, { params }),
  },
  patentResearch: {
    getList: (params) => apiGet(apiPatentResearch + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiPatentResearch}/${id}`, { params }),
    createItem: async (data) => {
      const res = await apiPost(apiPatentResearch, { data });
      await uploadImages(CAT_PATENT_RESEARCH, data.images, {
        patent_research_id: res.data.id,
      }).catch();
      return res;
    },
    updateItem: async (id, data) => {
      const res = await apiPatch(`${apiPatentResearch}/${id}`, { data });
      if (data.images) {
        const images = data.images.filter((img) => img.isNew || img.isDirty);
        await uploadImages(CAT_PATENT_RESEARCH, images, { 'research.id': id }).catch();
      }
      return res;
    },
    downloadFile: async (id) => {
      const res = await Backend.loadBlobData({
        url: `${apiPatentResearch}/${id}/file`,
        method: 'get',
        responseType: 'blob',
        headers: { accept: 'application/octet-stream' },
      });
      return res;
    },
  },
  patentResearchImages: {
    getList: (patent_research_id, params = {}) =>
      apiGet(apiPatentResearchImages, {
        params: {
          ...params,
          'research.id': patent_research_id,
        },
      }),
    getItem: (id, params) => apiGet(`${apiPatentResearchImages}/${id}`, { params }),
    createItem: (img, patent_research_id, is_default = false) =>
      createImage(CAT_PATENT_RESEARCH, img, { patent_research_id, is_default }),
    updateItem: (id, data) => apiPatch(`${apiPatentResearchImages}/${id}`, { data }),
    remove: (id) => apiDelete(`${apiPatentResearchImages}/${id}`),
  },
  productMarketCountries: {
    getList: (params) => apiGet(apiUrlProductMarketCountries + apiUrlSuffix, { params }),
    createItem: (data) => apiPost(apiUrlProductMarketCountries, { data }),
    updateItem: (id, data) => apiPatch(`${apiUrlProductMarketCountries}/${id}`, { data }),
    deleteItem: (id) => apiDelete(`${apiUrlProductMarketCountries}/${id}`),
  },
  patentResearchDocument: {
    getList: (params) => apiGet(apiPatentResearchDocument + apiUrlSuffix, { params }),
    createItem: (data) => apiPost(apiPatentResearchDocument, { data }),
    updateItem: (id, data) => apiPatch(`${apiPatentResearchDocument}/${id}`, { data }),
    deleteItem: (id) => apiDelete(`${apiPatentResearchDocument}/${id}`),
  },
  patentResearchDocumentComment: {
    createItem: (data) => apiPost(apiPatentResearchDocumentComment, { data }),
    updateItem: (id, data) => apiPatch(`${apiPatentResearchDocumentComment}/${id}`, { data }),
    deleteItem: (id) => apiDelete(`${apiPatentResearchDocumentComment}/${id}`),
  },
  additionalFieldNames: {
    getList: (params) => apiGet(apiUrlAdditionalFieldNames, { params }),
  },
  activitySpheres: {
    getList: (params) => apiGet(apiUrlActivitySpheres, { params }),
    getItem: (id, params) => apiGet(`${apiUrlActivitySpheres}/${id}`, { params }),
  },

  [CAT_FEATURES]: makeRequestsDict(apiUrlProductFeatures),
  [CAT_FEATURE_OPTIONS]: makeRequestsDict(apiUrlProductFeatureOptions),
  [CAT_NICE_CLASSES]: {
    getList: (params) => apiGet(apiNiceClasses, { params }),
  },
  [CAT_TRADEMARKS]: {
    create: (data) => apiPost(apiUrlTrademarks, { data, is_protected: true }),
  },
  getComponents: (params, cat, headers) => {
    const { clearParams, ...rest } = params;
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlComponents + apiUrlSuffix, preparedParams);
  },
  getComponentDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlComponents}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlComponents}/${id}`, { is_protected: true });
    }
  },
  getComponentsStats: () => apiGet(apiUrlComponentsStats),
  createComponent: (data) => apiPost(apiUrlComponents, { data, is_protected: true }),
  updateComponent: (id, data) => apiPatch(`${apiUrlComponents}/${id}`, { data, is_protected: true }),
  deleteComponent: (id) => apiDelete(`${apiUrlComponents}/${id}`, { is_protected: true }),

  [CAT_COMPONENT_PACKAGES]: {
    getList: (params) =>
      apiGet(apiComponentPackages + apiUrlSuffix, {
        params,
        is_protected: true,
      }),
  },

  [CAT_COMPONENT_CATEGORIES]: {
    getList: (params) =>
      apiGet(apiComponentCategories + apiUrlSuffix, {
        params,
        is_protected: true,
      }),
  },

  getCompanies: (params, cat, headers) => {
    const { clearParams, ...rest } = params;
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlCompanies + apiUrlSuffix, preparedParams);
  },
  getCurrencies: (params) => {
    return apiGet(apiUrlCurrencies, params);
  },
  getCurrencyItem: (id, params) => apiGet(`${apiUrlCurrencies}/${id}`, { params }),
  getCompanyDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlCompanies}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlCompanies}/${id}`, { is_protected: true });
    }
  },
  getCompaniesStats: () => apiGet(apiUrlCompaniesStats),
  createCompany: async (data) => {
    const res = await apiPost(apiUrlCompanies, { data, is_protected: true });
    await uploadImages(CAT_COMPANIES, data.images, {
      company_id: res.data.id,
    }).catch();
    return res;
  },
  updateCompany: async (id, data) => {
    if (data.foundationYear === '') {
      data.foundationYear = null;
    }
    const res = await apiPatch(`${apiUrlCompanies}/${id}`, {
      data,
      is_protected: true,
    });
    if (data.images) {
      const images = data.images.filter((img) => img.isNew || img.isDirty);
      await uploadImages(CAT_COMPANIES, images, { company_id: id }).catch();
    }
    return res;
  },
  [CAT_COMPANIES]: {
    remove: (id) => apiDelete(`${apiUrlCompanies}/${id}`, { is_protected: true }),
  },

  createContact: (data) => apiPost(apiUrlContacts, { data, is_protected: true }),
  getContacts: (params, cat, headers) => {
    const { clearParams, ...rest } = params;
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlContacts + apiUrlSuffix, preparedParams);
  },
  getContactsDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlContacts}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlContacts}/${id}`, { is_protected: true });
    }
  },
  getContactsStats: () => apiGet(apiUrlContactsStats),
  updateContact: async (id, data) => apiPatch(`${apiUrlContacts}/${id}`, { data }),

  getTrademarks: (params, cat, headers) => {
    let preparedParams = {
      is_protected: true,
    };
    if (params) {
      preparedParams.params = params;
    }
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlTrademarks + apiUrlSuffix, preparedParams);
  },
  getTrademarksDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlTrademarks}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlTrademarks}/${id}`, { is_protected: true });
    }
  },
  getTrademarksSuggestions: (companyID, params) =>
    apiGet(`${apiUrlTrademarks}/suggestions`, { params: { company: companyID, ...(params || {}) } }),

  getBrands: (params, cat, headers) => {
    const { clearParams, ...rest } = params;
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlProductBrands + apiUrlSuffix, preparedParams);
  },
  getBrandsDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlProductBrands}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlProductBrands}/${id}`, { is_protected: true });
    }
  },
  createBrand: (data) => {
    return apiPost(apiUrlProductBrands, { data, is_protected: true }).then(async (res) => {
      if (res.status === 201) {
        await uploadImages(CAT_BRANDS, data.images, {
          brand_id: res.data.id,
        }).catch();
      } else {
        throw new ApiError('Can not save brand', res);
      }
      return res;
    });
  },
  updateBrand: (id, data) => apiPut(`${apiUrlProductBrands}/${id}`, { data, is_protected: true }),
  deleteBrand: (id) => apiDelete(`${apiUrlProductBrands}/${id}`, { is_protected: true }),

  getProofRequests: (params) => apiGet(apiUrlProofRequests + apiUrlSuffix, { params, is_protected: true }),
  getProofRequestDetails: (id) => apiGet(`${apiUrlProofRequests}/${id}`),
  postProofRequest: (data) => apiPost(apiUrlProofRequests, { data }),
  deleteProofRequest: (id) => apiDelete(`${apiUrlProofRequests}/${id}`),
  changeProofRequestStatus: (id, status) => apiPut(`${apiUrlProofRequests}/${id}/status/${status}`),
  updateAttendingRequest: (id, data) => apiPatch(`${apiUrlAttendingRequests}/${id}`, { data }),
  getPatents: (params, ld = true, headers) => {
    const { clearParams, ...rest } = params;
    let url = apiUrlPatents;
    if (ld) {
      url += apiUrlSuffix;
    }
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(url, preparedParams);
  },
  getPatentDetails: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlPatents}/${id}`, {
        headers: headers,
        is_protected: true,
      });
    } else {
      return apiGet(`${apiUrlPatents}/${id}`, { is_protected: true });
    }
  },
  getPatentContents: (params) => apiGet(apiUrlPatentContents + apiUrlSuffix, { params }),
  getPatentDescriptions: (params) => apiGet(apiUrlPatentDescriptions + apiUrlSuffix, { params }),
  getPatentClaims: (params) => apiGet(apiUrlPatentClaims + apiUrlSuffix, { params }),
  createPatent: (data) => apiPost(apiUrlPatentsExplore, { data, is_protected: true }),
  createPatentInternalNote: (data) => apiPost(apiPatentInternalNotes, { data }),
  getPatentInternalNotes: (params) => apiGet(apiPatentInternalNotes, { params }),
  deletePatentInternalNote: (id) => apiDelete(`${apiPatentInternalNotes}/${id}`),
  updatePatentInternalNote: (id, data) => apiPatch(`${apiPatentInternalNotes}/${id}`, { data }),
  getAnalysisTypes: () => apiGet(apiAnalysisTypes),
  createAnalysis: (data) => apiPost(apiProductAnalysisOrders, { data }),
  search: (params) => Backend.get({ params, url: apiUrlSearch }, true),

  standingReports: {
    cleanAllProducts: (id) => apiPost(`${apiUrlStandingReports}/${id}/sections/clean`),
    cleanSectionProducts: ({ id, sectionId }) => apiPost(`${apiUrlStandingReports}/${id}/sections/${sectionId}/clean`),
  },
  postStandingReport: (data) => apiPost(apiUrlStandingReports + apiUrlSuffix, { data }),
  patchStandingReport: (id, data) => apiPatch(`${apiUrlStandingReports}/${id}`, { data, is_protected: true }),
  getStandingReports: (params, cat, headers) => {
    const { clearParams, ...rest } = params;
    let preparedParams = {
      params: rest,
      clearParams,
      is_protected: true,
    };
    if (headers) {
      preparedParams.headers = headers.headers;
    }
    return apiGet(apiUrlStandingReports + apiUrlSuffix, preparedParams);
  },
  getStandingReport: (id, headers) => {
    if (headers) {
      return apiGet(`${apiUrlStandingReports}/${id}`, {
        headers: headers,
      });
    } else {
      return apiGet(`${apiUrlStandingReports}/${id}`);
    }
  },
  postStandingReportSection: (id, data) => apiPost(`${apiUrlStandingReports}/${id}/sections`, { data }),
  deleteStandingReportSection: ({ id, sectionId }) => apiDelete(`${apiUrlStandingReports}/${id}/sections/${sectionId}`),
  putStandingReportSection: ({ id, sectionId, data }) =>
    apiPut(`${apiUrlStandingReports}/${id}/sections/${sectionId}`, { data }),
  addProductsToStandingReportSection: ({ id, sectionId, data }) =>
    apiPut(`${apiUrlStandingReports}/${id}/sections/${sectionId}/add`, {
      data,
    }),
  cloneStandingReport: (id) => apiPost(`${apiUrlStandingReports}/${id}/clone`),
  generateStandingReport: (id, data) => apiPost(`${apiUrlStandingReports}/${id}/generate`, { data }),
  deleteStandingReport: (id) => apiDelete(`${apiUrlStandingReports}/${id}`),
  deleteStandingReportProduct: ({ id, sectionId, data }) =>
    apiPut(`${apiUrlStandingReports}/${id}/sections/${sectionId}/delete`, {
      data,
    }),

  getModules: (params) => apiGet(apiUrlModules, { params }),
  types: {
    create: (data) => {
      return apiPost(apiUrlTypes, { data });
    },
    getList: (params) =>
      apiGet(apiUrlTypes, {
        params,
        headers: { Accept: 'application/ld+json' },
      }),
    getItem: (id, params) => apiGet(`${apiUrlTypes}/${id}`, { params }),
    update: (id, data) => {
      return apiPatch(`${apiUrlTypes}/${id}`, { data });
    },
  },
  productImages: {
    create: (img, product_id, is_default = false, session_interval = null) =>
      createImage(CAT_PRODUCTS, img, {
        product_id,
        is_default,
        session_interval,
      }),
    createWithLink: (link, product_id, is_default = false) =>
      apiPost(apiUrlProductImages, { params: { product_id, is_default }, data: { link } }),
    update: (id, data) => updateImage(CAT_PRODUCTS, id, data),
    remove: (id) => apiDelete(`${apiUrlProductImages}/${id}`),
  },
  companyImages: {
    create: (img, company_id, is_default = false) => createImage(CAT_COMPANIES, img, { company_id, is_default }),
    update: (id, data) => updateImage(CAT_COMPANIES, id, data),
    remove: (id) => apiDelete(`${apiUrlCompanyImages}/${id}`),
    getList: (company_id, params = {}) =>
      apiGet(apiUrlCompanyImages, {
        params: {
          ...params,
          'company.id': company_id,
        },
      }),
  },
  newsImages: {
    create: (img, news_id, intro = false) => createImage(CAT_NEWS, img, { news_id, intro }),
    remove: (id) => apiDelete(`${apiUrlNewsImages}/${id}`),
    getList: (news_id, params = {}) =>
      apiGet(apiUrlNewsImages, {
        params: {
          ...params,
          'news.id': news_id,
        },
      }),
    update: (id, file) => {
      const url = `${apiUrlNewsImages}/${id}`;
      const formData = new FormData();
      formData.append('file', file);
      return Backend.post({
        url,
        headers: {
          'Content-type': 'multipart/form-data',
        },
        data: formData,
      });
    },
  },
  [CAT_FILES]: {
    getList: (params) => apiGet(apiFiles + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiFiles}/${id}`, { params }),
    download: (link) => {
      let params = { url: `${apiHost}${link}`, auth: true, headers: { 'WIMS-loggable': true } };
      if (
        !link.includes('companies') &&
        !link.includes('products') &&
        !link.includes('components') &&
        !link.includes('events')
      ) {
        delete params.headers;
      }
      return Backend.loadData(params).then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new ApiError('Error', res);
        }
      });
    },
    downloadBlob: (link) => {
      let params = { url: `${apiHost}${link}`, auth: true, headers: { 'WIMS-loggable': true } };
      return Backend.loadBlobData(params).then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          throw new ApiError('Error', res);
        }
      });
    },
    update: (id, data) => apiPatch(`${apiFiles}/${id}`, { data }),
    create: uploadFile,
    remove: (id) => apiDelete(`${apiFiles}/${id}`),
  },
  [CAT_PATENT_CITATIONS]: {
    getList: (params) => apiGet(`${apiPatentCitations}`, { params }),
  },
  loadPatentLegalEvents: (patentID) => apiGet(`${apiPatents}/${patentID}/legal_events`),
  orderStatuses: {
    analysis: (params) =>
      apiGet(apiOrderStatuses, {
        params: { ...params, useInAnalysisOrder: true },
      }),
    product: (params) =>
      apiGet(apiOrderStatuses, {
        params: { ...params, useInProductOrder: true },
      }),
  },
  productConsumableVariations: {
    getList: (params) => apiGet(apiProductConsumableVariations + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiProductConsumableVariations}/${id}`, { params }),
    create: (data) => apiPost(apiProductConsumableVariations, { data }),
    update: (id, data) => apiPatch(`${apiProductConsumableVariations}/${id}`, { data }),
  },
  getProductMarketCountries: (params) => apiGet(apiUrlCountriesProductMarkets, { params }),
  uploadMarketCountryEvidence: (data) => apiPost(apiFiles, { data }), // apiUrlMarketCountriesFiles
  downloadEvidences: async (id) => {
    const res = await Backend.loadBlobData({
      url: `${apiUrlMarketCountriesFiles}/${id}`,
      method: 'get',
      responseType: 'blob',
      headers: { accept: 'application/octet-stream' },
    });
    return res;
  },
  [CAT_CONSUMABLE_VARIATIONS]: {
    create: (data) => apiPost(apiUrlProductVariations, { data }),
    update: (id, data) => apiPut(`${apiUrlProductVariations}/${id}`, { data }),
    remove: (id) => apiDelete(`${apiUrlProductVariations}/${id}`),
  },
  getProductVariations: (params) => apiGet(apiUrlProductVariations, { params }),
  updateProductOrderInfo: (id, status, staffComment) =>
    apiPatch(`${apiUrlProductOrders}/${id}`, { data: { status, staffComment } }),
  updateProductOrder: (id, status_id) => apiPut(`${apiUrlProducts}/order/${id}/status/${status_id}`),
  updateProductAnalysisOrder: (id, status_id) => apiPut(`${apiUrlProducts}/analysis-order/${id}/status/${status_id}`),
  deleteProductOrder: (id) => apiDelete(`${apiUrlProductOrders}/${id}`),
  deleteProductAnalysis: (id) => apiDelete(`${apiProductAnalysisOrders}/${id}`),
  staffStats: Object.fromEntries(staffStatsEntries),
  staffStatsItem: Object.fromEntries(staffStatsItemEntries),
  usergroups: {
    getList: (params) =>
      apiGet(apiUrlUserGroups, {
        params,
        headers: { Accept: 'application/ld+json' },
      }),
  },
  samples: {
    getList: (params) => apiGet(apiSamples, { params }),
    getLDList: (params) => apiGet(apiSamples + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiSamples}/${id}`, { params }),
    create: (data) => apiPost(apiSamples, { data }),
    update: (id, data) => apiPatch(`${apiSamples}/${id}`, { data }),
  },
  newsDomains: {
    getList: (params) => {
      try {
        const url = 'https://news-app.winglegroup.com/api/domains.jsonld';
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/ld+json' },
          auth: true,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
  },
  newsTags: {
    getList: (params) => {
      try {
        const url = 'https://news-app.winglegroup.com/api/tags.jsonld';
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/ld+json' },
          auth: true,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
  },
  [CAT_COMPANY_NEWS]: {
    getList: (params) => {
      try {
        const url = 'https://news-app.winglegroup.com/api/news.jsonld';
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/ld+json', 'WIMS-loggable': true },
          auth: true,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
    getItem: (id, params) => {
      try {
        const url = `https://news-app.winglegroup.com/api/news/${id}`;
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/json' },
          auth: true,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
  },
  searchProductsByImage: (data) => {
    try {
      const url = `https://image-based-product-search.winglegroup.com/api/similar-product-search`;
      return Backend.request(url, 'post', {
        data,
        auth: true,
      });
    } catch (err) {
      const { status } = err?.response || {};
      if (status >= 400) {
        return err?.response;
      } else {
        const { message } = err.toJSON();
        throw new Error(message);
      }
    }
  },
  [CAT_NEWS]: {
    getList: (params) => apiGet(apiUrlNews, { params }),
    getLDList: (params, headers) => {
      const { clearParams, ...rest } = params;
      let preparedParams = {
        params: rest,
        clearParams,
      };
      if (headers) {
        preparedParams.headers = headers;
      }
      return apiGet(apiUrlNews + apiUrlSuffix, preparedParams);
    },
    getItem: (id, params) => apiGet(`${apiUrlNews}/${id}`, { ...params }),
    create: async (data) => {
      const res = await apiPost(apiUrlNews, { data });
      await uploadImages(CAT_NEWS, data.images, {
        news_id: res.data.id,
      }).catch();
      return res;
    },
    update: async (id, { images, ...data } = {}) => {
      const res = await apiPatch(`${apiUrlNews}/${id}`, {
        data,
        is_protected: true,
      });
      if (images?.length) {
        await Promise.all(
          images.map(({ id: imgId, value, isNew, data }) => {
            if (!value) {
              return Promise.resolve();
            }
            if (imgId) {
              return DB.newsImages.update(imgId, value);
            } else if (isNew) {
              return DB.newsImages.create(value, id, data.intro);
            }
          })
        );
      }
      return res;
    },
    remove: (id) => apiDelete(`${apiUrlNews}/${id}`),
  },
  sampleConditions: {
    getList: (params) => apiGet(apiSampleConditions, { params }),
    getLDList: (params) => apiGet(apiSampleConditions + apiUrlSuffix, { params }),
    getItem: (id, params) => apiGet(`${apiSampleConditions}/${id}`, { params }),
    create: (data) => apiPost(apiSampleConditions, { data }),
    update: (id, data) => apiPatch(`${apiSampleConditions}/${id}`, { data }),
  },
  cpcClassifications: {
    getList: (params) => apiGet(apiCpcClassifications, { params }),
  },
  ipcClassifications: {
    getList: (params) => apiGet(apiIpcClassifications, { params }),
  },
  users: {
    getList: (params, config = {}) => apiGet(apiUrlUsers, { params, is_protected: true, ld: true, ...config }),
    getItem: (id) => apiGet(`${apiUrlUsers}/${id}`),
    getRoles: (params) => apiGet(`${apiUrlRoles}`, { params }),
    update: (id, data) => apiPatch(`${apiUrlUsers}/${id}`, { data }),
    create: (data) => apiPost(`${apiUrlUsers}`, { data }),
    getStaffStatistics: (params) => apiGet(apiUrlUsersStaffStatistics, { params }),
    getUserStats: async (id, params) =>
      apiGet(`${apiUrlUsers}/${id}/staff_statistics`, { params }).then((res) => res.data || null),
    getUserProductivity: async (id, params) =>
      apiGet(`${apiUrlUsers}/${id}/staff_productivity`, { params }).then((res) => res.data || null),
    getUserTimeConsumption: async (id, params) =>
      apiGet(`${apiUrlUsers}/${id}/staff_time_consumption`, { params }).then((res) => res.data || null),
  },
  researchesDomains: {
    getList: (params) => apiGet(apiUrlResearchesDomains + apiUrlSuffix, { params, is_protected: true }),
  },
  researchesCategories: {
    getList: (params) => apiGet(apiUrlResearchesCategories + apiUrlSuffix, { params, is_protected: true }),
  },
  researchesAuthors: {
    getList: (params) => apiGet(apiUrlResearchesAuthors + apiUrlSuffix, { params, is_protected: true }),
  },
  researches: {
    getList: (params, cat, headers) => {
      const { clearParams, ...rest } = params;
      let preparedParams = {
        params: rest,
        clearParams,
        is_protected: true,
      };
      if (headers) {
        preparedParams.headers = headers;
      }
      return apiGet(apiUrlResearches + apiUrlSuffix, preparedParams);
    },
    getItem: (id) => apiGet(`${apiUrlResearches}/${id}`),
    update: (id, data) => apiPatch(`${apiUrlResearches}/${id}`, { data }),
  },
  brands: {
    images: {
      create: (img, id, is_default = false) => createImage(CAT_BRANDS, img, { brand_id: id, is_default }),
      update: (id, data) => updateImage(CAT_BRANDS, id, data),
      remove: (id) => apiDelete(`${apiUrlBrandImages}/${id}`),
      getList: (id, params = {}) =>
        apiGet(apiUrlBrandImages, {
          params: {
            ...params,
            'brand.id': id,
          },
        }),
    },
  },
  trademark: {
    loadOffices: async (params) => apiGet(apiUrlsTrademarkOffices, { params }),
  },
  companyNews: {
    getCompanyNewsList: async (params) => {
      try {
        const url = 'https://news-app.winglegroup.com/api/news';
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/json' },
          auth: true,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
  },
  events: {
    get: (params, cat, headers) => {
      const { clearParams, ...rest } = params;
      let preparedParams = {
        params: rest,
        clearParams,
      };
      if (headers) {
        preparedParams.headers = headers;
      }
      return apiGet(apiUrlEvents + apiUrlSuffix, preparedParams);
    },
    create: (data) => apiPost(apiUrlEvents, { data }),
    update: (id, data) => apiPatch(`${apiUrlEvents}/${id}`, { data }),
    getItem: (id, headers) => {
      if (headers) {
        return apiGet(`${apiUrlEvents}/${id}`, {
          headers: headers,
        });
      } else {
        return apiGet(`${apiUrlEvents}/${id}`);
      }
    },
    remove: (id) => apiDelete(`${apiUrlEvents}/${id}`),
  },
  eventTypes: {
    get: (params) => apiGet(apiUrlEventTypes, { params }),
    create: (data) => apiPost(apiUrlEventTypes, { data }),
    update: (id, data) => apiPatch(`${apiUrlEventTypes}/${id}`, { data }),
    getItem: (id) => apiGet(`${apiUrlEventTypes}/${id}`),
    remove: (id) => apiDelete(`${apiUrlEventTypes}/${id}`),
  },
  attendingRequests: {
    get: (params) => apiGet(apiUrlAttendingRequests, { params }),
  },
  eventAtendingReports: {
    create: (data) => apiPost(apiUrlEventAtendingRequest, { data }),
  },
  eventThemes: {
    get: (params) => apiGet(apiUrlEventThemes, { params }),
    getItem: (id) => apiGet(`${apiUrlEventThemes}/${id}`),
    create: (data) => apiPost(apiUrlEventThemes, { data }),
    update: (id, data) => apiPatch(`${apiUrlEventThemes}/${id}`, { data }),
    delete: (id) => apiDelete(`${apiUrlEventThemes}/${id}`),
  },
  eventImages: {
    create: (img, event_id, is_default = false, session_interval = null) =>
      createImage(CAT_EVENTS, img, {
        event_id,
        is_logo: is_default,
        session_interval,
      }),
    update: (id, data) => updateImage(CAT_EVENTS, id, data),
    remove: (id) => apiDelete(`${apiUrlEventImages}/${id}`),
  },
  getEventImages: (event_id, params = {}) =>
    apiGet(apiUrlEventImages, {
      params: {
        ...params,
        'event.id': event_id,
      },
    }),
  assistant: assistantService,
  similarProducts,
  ingredientsAndNutrients,
  userSessions: userSessionsAPI,
  ...dealsAPI,
  [CAT_PRICE_SOURCE_TYPES]: {
    getList: (params) => apiGet(apiUrlPriceSourceTypes, { params }),
  },
};

const InfoSources = {
  checkUrl: async (url, id) => {
    try {
      const config = {
        url: 'https://images-extractor.winglegroup.com/api/extract-images',
        headers: { accept: 'application/json' },
        data: new FormData(),
        auth: true,
      };
      config.data.append('id', id);
      config.data.append('url', url);
      const response = await Backend.post(config);
      return response;
    } catch (err) {
      const { response } = err || {};
      if (response) {
        return response;
      } else {
        const { message } = err.toJSON();
        throw new Error(message);
      }
    }
  },
  news: {
    query: async (params) => {
      try {
        const url = 'https://wimsprod.smartcoders.dev/api/News/query';
        // const url = 'http://localhost:3000/api/News/query';
        return Backend.request(url, 'get', {
          params,
          headers: { accept: 'application/json' },
          auth: false,
        });
      } catch (err) {
        const { status } = err?.response || {};
        if (status >= 400) {
          return err?.response;
        } else {
          const { message } = err.toJSON();
          throw new Error(message);
        }
      }
    },
  },
  recognizeImage: async (image) => {
    const config = {
      url: 'https://text-recognition.winglegroup.com/api/text-recognition',
      headers: { 'content-type': 'multipart/form-data' },
      data: new FormData(),
      auth: true,
    };
    config.data.append(isFile(image) ? 'file' : 'url', image);
    return Backend.post(config);
  },
};

async function cloneProduct(id, params) {
  return apiPost(`${apiUrlProducts}/${id}/clone`, { params }).then((response) => {
    if (response.status === 201) {
      return response.data;
    } else {
      throw new ApiError('Could not clone product', response);
    }
  });
}

async function cloneProducts(params) {
  return apiPost(`${apiUrlProducts}/clone`, { params }).then((response) => {
    const status = (response.status / 100) >> 0;
    if (status === 2) {
      return response.data;
    } else {
      throw new ApiError('Could not clone product', response.data.title);
    }
  });
}

function isFile(value) {
  return value instanceof File;
}

async function createImage(category, value, params) {
  const url = getCatImagesUrl(category);
  if (!url) {
    throw new Error('Invalid category');
  }
  if (isFile(value)) {
    return saveImageFile(url, value, params);
  } else {
    return saveImageLink(url, value, params);
  }
}

async function saveImageFile(url, value, params) {
  const data = new FormData();
  data.append('file', value);
  return Backend.post({
    url,
    params,
    headers: { 'content-type': 'multipart/form-data' },
    data,
  });
}

function saveImageLink(url, value, params) {
  const data = JSON.stringify({ link: value });
  return Backend.post({
    url,
    params,
    headers: { 'content-type': 'application/json' },
    data,
  });
}

async function updateImage(category, id, data, params) {
  const url = getCatImagesUrl(category);
  if (!url) {
    throw new Error('Invalid category');
  }
  return apiPut(`${url}/${id}`, { data, params });
}

function getCatImagesUrl(category) {
  return {
    [CAT_PRODUCTS]: apiUrlProductImages,
    [CAT_COMPANIES]: apiUrlCompanyImages,
    [CAT_NEWS]: apiUrlNewsImages,
    [CAT_BRANDS]: apiUrlBrandImages,
    [CAT_EVENTS]: apiUrlEventImages,
    [CAT_PATENT_RESEARCH]: apiPatentResearchImages,
  }[category];
}

async function uploadFile(data) {
  const formData = new FormData();
  for (const [key, val] of Object.entries(data)) {
    formData.set(key, val);
  }
  return Backend.post({
    url: apiFiles,
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formData,
  });
}

async function uploadImages(category, images, params) {
  if (images?.length) {
    return Promise.all(
      images.map((image) => {
        const { types, isNew, isDefault, value, data, isDirty } = image;
        const queryParams = { ...params };
        if (category === CAT_NEWS) {
          queryParams.intro = data?.intro;
        } else {
          queryParams.is_default = isDefault;
        }
        if (isNew) {
          return createImage(category, value, queryParams)
            .then((res) => {
              if (res.status === 201) {
                if (types && types.length) {
                  return updateImage(category, res.data.id, { types });
                }
              }
              return res;
            })
            .catch();
        } else if (isDirty) {
          if (category === CAT_NEWS) {
            return;
          }
          return updateImage(category, image.id, { default: isDefault, types });
        }
      })
    );
  }
}

const availableCategoriesWithCounters = ['products', 'components', 'companies', 'contacts', 'trademarks', 'patents'];

const getAllCounters = async () => {
  const categories = availableCategoriesWithCounters;

  const responses = await Promise.all(
    categories.map((category) => {
      const url = Backend.config[`apiUrl${upperFirst(category)}Total`];
      return Backend.request(url, 'get', { auth: false });
    })
  );

  return responses.map(({ data }, index) => {
    const { total: value } = data;
    const label = upperFirst(categories[index]);
    const category = label.toLowerCase();

    return { value, category, label };
  });
};

const Comment = {
  async createComment(data) {
    return Backend.post({
      url: apiUrlComments,
      data,
    });
  },
  async getComments(queryParams = {}) {
    const params = {
      page: 1,
      itemsPerPage: 100,
      ...queryParams,
    };

    const { data } = await apiGet(apiUrlComments, { params });

    return [...data].reverse().map(({ comment, createdAt, created }) => ({
      text: comment,
      date: new Date(createdAt).toDateString(),
      user: created,
    }));
  },
  getEntityByCategory(category) {
    const categoryToEntityMap = {
      companies: 'company',
    };

    return categoryToEntityMap[category] || category.slice(0, category.length - 1);
  },
};

const Contact = {
  sendOrder: async (product, extra = {}) => {
    return DB.postProductOrder(product.id, extra);
  },
};

const passwordReset = {
  request: (data) => apiPost(resetPasswordRequest, { data, is_protected: true, auth: false }),
  reset: (data) => apiPost(resetPasswordReset, { data, is_protected: true, auth: false }),
};

const Dataman = {
  SaveLead,
  getReport, // This function moved to the services folder. Still here for compatibility
  getAllCounters,
  passwordReset,
  availableCategoriesWithCounters,
  User,
  DB,
  InfoSources,
  Filter,
  Comment,
  Contact,
  setErrorHandler(handler) {
    Backend.onError = handler;
  },
  refreshTokenHandler(handler) {
    Backend.onRefreshToken = handler;
  },
  showDrafts(state = false) {
    Backend.showDrafts = state;
  },
  cancelRequests: Backend.cancel,
};

export default Dataman;
